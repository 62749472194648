import '../App.css';
import block from "../assets/icons/block.png"
import security from "../assets/icons/security.png"
import wing from "../assets/icons/wing.png"
import logo from "../assets/brand/daloslogo.png"
import dalostext from "../assets/brand/dalostext.png"
import Navbar from '../modals/navbar';
import { useEffect, useState } from 'react';
import { EffectCreative, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';



function App() {

  const [isMobile, setIsMobile] = useState(false);
  //------------------------------------------------------------------------------ 
  useEffect(() => {
        const checkWidth = () => {
          setIsMobile(window.innerWidth < 992); // Adjust breakpoint as needed
        };
        checkWidth();
        window.addEventListener('resize', checkWidth);
    
        // Cleanup function
        return () => {
          window.removeEventListener('resize', checkWidth);
        };
      }, []);  

  return (
    // navbar-home-about us-services-contact

    <section id='app'>
      <Navbar/>
      <section id="home">
        <div className='container'>
          <div className='row'>
            <div className='col-12 text-center'>
              <div className='homeWrapper'>
                <div className='homeText'>
                  <p className='text-center'> Unlocking Tomorrow, Today - Where Blockchain Meets Infinite Possibility </p>
                  <button><a href='#aboutus'>About Us</a></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="aboutus">
        <div className='container ab'>
          <div className='row'>
            <div className='col-12 text-center'>
              <div className='aboutWrapper'>
                <div className='aboutText'>
                  <h1>About Us</h1>
                </div>
              </div>
            </div>
          </div>
          <div className='row'  >
            <div className='col-12'>
              <div className='aboutWrapper'>
                <div className='aboutText'>
                    {!isMobile ? (<>
                      <div className='row'>
                          <div className='col-4 d-flex justify-content-center'>
                            <div className='row'>
                                <div className='col-12 text-center'>
                                  <img src={block} className=''></img> <br></br>
                                </div>
                                <div className='col-12'>
                                <p>At Dalos Network, we are dedicated to revolutionizing industries through innovative blockchain solutions. With a focus on decentralization, 
                                security, and accessibility, we transcend boundaries across various blockchain networks. Our mission is to create a more equitable and accessible 
                                digital world for all.
                                </p>
                                </div>
                            </div>
                          </div>
                          <div className='col-4 d-flex justify-content-center'>
                            <div className='row'>
                                <div className='col-12 text-center'>
                                  <img src={security} className=''></img> <br></br>
                                </div>
                                <div className='col-12'>
                                <p>We prioritize security, privacy, and accessibility, ensuring our applications uphold
                                the highest standards across multiple blockchain networks. Our goal is to leverage blockchain 
                                technology to its fullest potential, fostering inclusivity and empowering individuals globally.
                                </p>
                                </div>
                            </div>
                          </div>
                          <div className='col-4 d-flex justify-content-center'>
                            <div className='row'>
                                <div className='col-12 text-center'>
                                  <img src={wing} className=''></img> <br></br>
                                </div>
                                <div className='col-12'>
                                <p>Join us at Dalos Network as we lead the charge in pioneering decentralized solutions. 
                                Together, we'll shape a future where blockchain transforms industries and creates 
                                opportunities for everyone.
                                </p>
                                </div>
                            </div>
                          </div>
                      </div>
                    </>
                    ):(
                      <>
                        <div className='row'>
                      <Swiper
                      allowTouchMove={true}
                      loop={true}
                      autoplay={true}
                      speed={1000}
                      slidesPerView={1}
                      pagination={{
                        dynamicBullets: true,
                      }}
                      modules={[Pagination]}
                      className="mySwiper"
                    >
                      <SwiperSlide>
                        <div className='col-12 d-flex justify-content-center'>
                          <div className='row  mb-4'>
                              <div className='col-12 text-center'>
                                <img src={block} className=''></img> <br></br>
                              </div>
                              <div className='col-12'>
                              <p>
                              At Dalos Network, we are dedicated to revolutionizing industries through innovative 
                              blockchain solutions. With a focus on decentralization, security, and accessibility, 
                              we transcend boundaries across various blockchain networks. Our mission is to cre
                              ate a more equitable and accessible digital world for all.
                              </p></div>
                          </div>
                        </div>
                      </SwiperSlide>

                      <SwiperSlide>
                        <div className='col-12 d-flex justify-content-center'>
                          <div className='row  mb-4'>
                              <div className='col-12 text-center'>
                                <img src={security} className=''></img> <br></br>
                              </div>
                              <div className='col-12'>
                              <p>
                              We prioritize security, privacy, and accessibility, ensuring our applications uphold
                              the highest standards across multiple blockchain networks. Our goal is to leverage blockchain 
                              technology to its fullest potential, fostering inclusivity and empowering individuals globally.</p>
                              </div>
                          
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className='col-12 d-flex justify-content-center'>
                          <div className='row  mb-4'>
                              <div className='col-12 text-center'>
                                <img src={wing} className=''></img> <br></br>
                              </div>
                              <div className='col-12'>
                              <p>
                              Join us at Dalos Network as we lead the charge in pioneering decentralized solutions. 
                              Together, we'll shape a future where blockchain transforms industries and creates 
                              opportunities for everyone.</p>
                              </div>
                          
                          </div>
                        </div>
                      </SwiperSlide>
                    </Swiper>
                    </div>
                      </>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      <section id="services">
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <div className='serviceWrapper'>
                <div className='serviceText'>
                  <h1 className='text-center serviceHeader'>Services</h1>
                  <div className='row sr'>
                    <div className='col-12 col-lg-6 d-flex justify-content-center'>
                      <div className='row p-0 pe-lg-5 sr1'>
                          <div className='col-12 text-center serviceHeader2'>
                            <h5>
                              Software
                            </h5>
                          </div>
                          <div className='col-12'>
                            <p>
                              Join us at Dalos Network as we lead the charge in pioneering decentralized solutions. Together, we'll shape a future where blockchain transforms industries and creates opportunities for everyone.
                            </p>
                          </div>
                      </div>
                    </div>
                    <div className='col-12 col-lg-6 d-flex justify-content-center'>
                      <div className='row p-0 ps-lg-5 sr1' >
                          <div className='col-12 text-center serviceHeader2'>
                            <h5>
                              Consultancy
                            </h5>
                          </div>
                          <div className='col-12'>
                            <p>
                              Join us at Dalos Network as we lead the charge in pioneering decentralized solutions. Together, we'll shape a future where blockchain transforms industries and creates opportunities for everyone.
                            </p>
                        </div>  
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row d-none'>
            <div className='col-12'>
              <div className='serviceWrapper'>
                <div className='serviceText'>
                  <div className='row'>
                    <div className='col-6 d-flex justify-content-center'>
                      <div className='row'>
                          <div className='col-12 text-center'>
                            Software
                          </div>
                          <div className='col-12'>
                          Join us at Dalos Network as we lead the charge in pioneering decentralized solutions. Together, we'll shape a future where blockchain transforms industries and creates opportunities for everyone.</div>
                      </div>
                    </div>
                    <div className='col-6 d-flex justify-content-center'>
                      <div className='row'>
                          <div className='col-12 text-center'>
                            Consultancy
                          </div>
                          <div className='col-12'>
                          Join us at Dalos Network as we lead the charge in pioneering decentralized solutions. Together, we'll shape a future where blockchain transforms industries and creates opportunities for everyone.</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
     
      </section>

      <section id="contact" className='d-none'>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
            contact
            </div>
          </div>
        </div>
      </section>

      <section id="footer">
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <div className='footerWrapper'>
                <div className='footerText'>
                  <div className='row text-center'>
                    <div className='col-12'>
                      <img className='footerLogo' src={logo}></img>
                    </div>
                    <div className='col-12'>
                      <img className="footerDalos" src={dalostext}></img>
                    </div>
                    <div className='col-12 mt-5'>
                      <a href='https://www.linkedin.com/company/dalos-network/about/' target='_blank'><i class="footerIcon fa-brands fa-linkedin"></i></a>
                      <a href='https://twitter.com/dalosnetwork' target='_blank'><i class="footerIcon fa-brands fa-square-x-twitter"></i></a>
                    </div>
                    <div className='col-12 mt-3'>
                      <p className='footerMail'>info@dalosnetwork.com</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </section>
  );
}

export default App;
