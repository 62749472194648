import '../App.css';
import logo from "../assets/brand/daloslogo.png"
import dalostext from "../assets/brand/dalostext.png"

function Navbar() {
  return (
    // navbar-home-about us-services-contact
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark sticky-top">
    <div class="container">
      <a class="navbar-brand" href="#">
        <img className='logo' src={logo} alt="" />
        <img className='dalosnetwork ms-3 ' src={dalostext} alt="" />
      </a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <a class="nav-link active" aria-current="page" href="#aboutus">About Us</a>
          </li>
          <li class="nav-item">
            <a class="nav-link active" href="#services">Services</a>
          </li>
          <li class="nav-item">
            <a class="nav-link active" href="#footer">Contact Us</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
     
  );
}

export default Navbar;
